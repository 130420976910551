import React from "react"
import styled from "styled-components"

import { colors } from "../utils/colors"
import { Row, Col, Container } from "react-bootstrap"
import Img404 from "../assets/images/mx/404.png"
import Layout from "../components/layout"
import PrimaryButton from "../components/PrimaryButton"
import PrimaryTitle from "../components/PrimaryTitle"
import SecondaryTitle from "../components/SecondaryTitle"
import SEO from "../components/Seo"
import ThirdTitle from "../components/ThirdTitle"

export const ParentContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  text-align: center;
  justify-content: center;
  @media (max-width: 768px) {
    height: initial;
  }
`
export const Description = styled.div`
  width: 36vw;
  @media (max-width: 768px) {
    width: initial;
  }
  a {
    color: white;
  }
`
export default () => {
  return (
    <Layout background={colors.GreenJade}>
      <SEO title="404: página no encontrada" />
      <Container>
        <Row>
          <Col>
            <ParentContainer>
              <Description>
                <PrimaryTitle
                  color={colors.White}
                  family="ubuntu"
                  height="86.4px"
                  size="72px"
                  weight="normal"
                >
                  Error 404
                </PrimaryTitle>
                <SecondaryTitle
                  color={colors.Midnight}
                  family="ubuntu"
                  height="40px"
                  size="40px"
                  weight="normal"
                >
                  No encontramos esta página
                </SecondaryTitle>
                <ThirdTitle
                  color={colors.White}
                  family="cabin"
                  height="26px"
                  size="18px"
                  weight="normal"
                  className="pb-5"
                >
                  Lo que sí tenemos, es una Solución para tus deudas y
                  consolidar tus proyectos. Vuelve a nuestro{" "}
                  <a href="/">Home</a> para conocerla.
                </ThirdTitle>
                <img src={Img404} alt="Página de 404" />
                <br />
                <PrimaryButton href="/" className="mt-5 mb-5">
                  VOLVER AL HOME
                </PrimaryButton>
              </Description>
            </ParentContainer>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
