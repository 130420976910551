import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

export const PrimaryTitleStyle = styled(
  ({ children, marginBottom, index, sMSize, ...props }) => (
    <h1 {...props}>{children}</h1>
  )
)`
  color: ${props => props.color};
  font-family: Ubuntu;
  font-size: ${props => props.size};
  font-weight: ${props => props.weight};
  ${props => props.index && "z-index: 10"};
  letter-spacing: ${props => props.spacing};
  line-height: ${props => props.height};
  margin-bottom: ${props => props.marginBottom};
  text-align: ${props => (props.left ? "left" : "right")};
  text-align: inherit;
  position: relative;
  z-index: 1;
  @media (max-width: 425px) {
    font-size: ${props => (!props.sMSize ? "40px" : "32px")};
  }
`

const PrimaryTitle = ({
  center,
  children,
  className,
  color,
  height,
  left,
  spacing,
  marginBottom,
  right,
  size,
  weight,
  index,
  sMSize,
}) => {
  return (
    <PrimaryTitleStyle
      center={center}
      className={className}
      color={color}
      height={height}
      left={left}
      spacing={spacing}
      marginBottom={marginBottom}
      right={right}
      size={size}
      sMSize={sMSize}
      index={index}
      weight={weight}
    >
      {children}
    </PrimaryTitleStyle>
  )
}

PrimaryTitle.prototype = {
  children: PropTypes.element.isRequired,
  color: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
}

export default PrimaryTitle
