import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Button } from "react-bootstrap"

export const ButtonCenter = styled.div`
  display: flex;
  justify-content: center;
`
export const PrimaryButtonStyle = styled(({ backgroundColor, ...props }) => (
  <Button {...props} />
))`
  width: ${props => props.width};
  height: ${props => props.height};
  margin: ${props => props.margin};
  background-color: ${props =>
    !props.backgroundColor ? "#00c986" : props.backgroundColor};
  border-color: ${props =>
    !props.backgroundColor ? "#00c986" : props.backgroundColor};
  color: #ffffff;
  font-family: ubuntu;
  font-size: ${props => (!props.size ? "14px" : props.size)};
  font-weight: 600;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  transition: 0.5s;
  &:hover {
    background-color: ${props =>
      !props.backgroundColor ? "#00c986" : props.backgroundColor};
    border-color: ${props =>
      !props.backgroundColor ? "#00c986" : props.backgroundColor};
    opacity: 0.8;
  }
  &:focus {
    background-color: ${props =>
      !props.backgroundColor ? "#00c986" : props.backgroundColor};
    border-color: ${props =>
      !props.backgroundColor ? "#00c986" : props.backgroundColor};
  }
  &:active {
    background-color: ${props =>
      !props.backgroundColor ? "#00c986" : props.backgroundColor} !important;
    border-color: ${props =>
      !props.backgroundColor ? "#00c986" : props.backgroundColor} !important;
  }

  &:disabled {
    background-color: ${props =>
      !props.backgroundColor ? "#00c986" : props.backgroundColor} !important;
    border-color: ${props =>
      !props.backgroundColor ? "#00c986" : props.backgroundColor} !important;
  }

  @media (max-width: 1024px) {
    font-size: 12px;
    letter-spacing: 0.5px;
  }
  @media (max-width: 425px) {
    order: ${props => props.order};
  }
`

const PrimaryButton = ({
  backgroundColor,
  children,
  className,
  color,
  disabled,
  height,
  href,
  onClick,
  size,
  type,
  width,
  margin,
}) => (
  <PrimaryButtonStyle
    backgroundColor={backgroundColor}
    className={className}
    color={color}
    disabled={disabled}
    height={height}
    href={href}
    onClick={onClick}
    size={size}
    type={type}
    width={width}
    margin={margin}
  >
    {children}
  </PrimaryButtonStyle>
)

PrimaryButton.prototype = {
  children: PropTypes.element.isRequired,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
}

export default PrimaryButton
