import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

export const ThirdTitleStyle = styled(
  ({ children, marginBottom, textAlign, sMHeight, ...props }) => (
    <h3 {...props}>{children}</h3>
  )
)`
  color: ${props => props.color};
  font-family: ${props => props.family};
  font-size: ${props => props.size};
  font-weight: ${props => props.weight};
  line-height: ${props => props.height};
  margin-bottom: ${props => props.marginBottom};
  text-align: ${props => props.textAlign};

  @media (max-width: 1024px) {
    font-size: 21px;
  }
  @media (max-width: 425px) {
    line-height: ${props => props.sMHeight};
  }
`
const ThirdTitle = ({
  children,
  className,
  color,
  family,
  height,
  marginBottom,
  right,
  size,
  sMHeight,
  textAlign,
  weight,
}) => {
  return (
    <ThirdTitleStyle
      className={className}
      color={color}
      family={family}
      height={height}
      marginBottom={marginBottom}
      right={right}
      size={size}
      sMHeight={sMHeight}
      textAlign={textAlign}
      weight={weight}
    >
      {children}
    </ThirdTitleStyle>
  )
}

ThirdTitle.prototype = {
  children: PropTypes.element.isRequired,
  color: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
}

export default ThirdTitle
