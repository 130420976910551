import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

export const SecondaryTitleStyle = styled(
  ({ children, marginBottom, ...props }) => <h2 {...props}>{children}</h2>
)`
  color: ${props => props.color};
  font-family: Ubuntu;
  font-size: ${props => props.size};
  font-weight: normal;
  line-height: ${props => props.height};
  margin-bottom: ${props => props.marginBottom};
  text-align: ${props => (props.left ? "left" : "right")};
  text-align: ${props => props.center && "center"};
  text-align: inherit;
  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
`
const SecondaryTitle = ({
  center,
  children,
  className,
  color,
  height,
  left,
  marginBottom,
  right,
  size,
}) => {
  return (
    <SecondaryTitleStyle
      center={center}
      className={className}
      color={color}
      height={height}
      left={left}
      marginBottom={marginBottom}
      right={right}
      size={size}
    >
      {children}
    </SecondaryTitleStyle>
  )
}

SecondaryTitle.prototype = {
  children: PropTypes.element.isRequired,
  color: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
}

export default SecondaryTitle
